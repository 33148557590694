<template>
   <LayoutBase>
      <div class="content-box-thehome-second">
         <div class="the-home-second-img"><img src="../assets/img/start-page-preview.png" alt="" /></div>
         <div class="content-the-home">
            <h1 class="the-home-second-title">Do you wake up tired and don't know why?</h1>
            <p class="the-home-second-paragraf">
               Do you want to increase your energy levels and feel more productive in just 7 days? Just imagine how much
               you can accomplish in one day if you improve your mood and motivation!
            </p>
            <p class="the-home-second-paragraf">
               Take a short test and get free recommendations for improving your sleep
            </p>
            <SWButton @click="goToQuiz" variant="primari" size="big" fullWidth>Start</SWButton>
            <p class="the-home-second-recomeid">
               Before starting the insomnia treatment, ask your doctor about your symptoms.
            </p>
            <div class="the-home-secong-img-company">
               <img src="../assets/img/logoCompany.png" alt="" />
            </div>
            <p class="copyright the-home-second-recomeid">Copyright © 2023 FUNDACJA FIRMA DLA KAŻDEGO</p>
         </div>
		<button ref="clickImitate" class="clickImitate" @click="imitateClick"></button>
      </div>
   </LayoutBase>
</template>

<script>
import LayoutBase from '../components/layouts/LayoutBase.vue';
import { SWButton } from 'sw-ui-kit';
import appUrl from '../store/appUrl';

const QUIZ_NAME = 'quiz';
/* import TheModalWindow from '../components/TheModalWindow.vue'; */
/* import appUrl from '../store/appUrl'; */
export default {
   components: {
      LayoutBase,
      SWButton,
      /*   HomePromo,
      HomeQuiz, */
      /* TheModalWindow, */
      /*    Messangers,
      AuthForm, */
   },
   mounted() {
	this.$nextTick(() => {
       this.$refs.clickImitate.click();
	});
	const event = new KeyboardEvent('keypress', {
         'key': 'a'
      });
      document.dispatchEvent(event);
 
    if (this.$route.query.verify_token) {
         this.verifyEmail();
      }
	
    sessionStorage.setItem('userCameFromMain', true);
	console.log('HOME SECOND')
	history.pushState(null, null, location.href);
	window.onpopstate = function () {
		history.go(1);
	};

	//window.onunload = function () { return null};
   },

   methods: {
	imitateClick() {
		console.log('CLICKED')
	},
      goToQuiz() {
         this.$router.push({ name: QUIZ_NAME });
      },
      async verifyEmail() {
         try {
            let data = await this.$http.post('auth/verify/email', {
               hash: this.$route.query.verify_token,
            });
            localStorage.user_token = data.data.token;
            window.location.replace(appUrl + `?token=${data.data.token}`);
         } catch (error) {
            alert('wrong token');
         }
      },
   },
   /* 
	provide() {
		return {
			toggleModal: this.toggleModal,
		};
	},

	data() {
		return {
			isModalOpen: false,
		};
	},

	

	methods: {
		toggleModal() {
			this.isModalOpen = !this.isModalOpen;
		},

		, */

   /*       async authUser() {
         try {
            let data = await this.$http.post('auth/singin', {
               phone: this.postValue.phone,
               password: this.postValue.password,
            });
            localStorage.user_token = data.data.token;
            this.$router.push({ name: 'app-main' });
         } catch (error) {
            alert('Something went wrong');
         }
      }, 
	},
      */
};
</script>

<style scoped lang="sass">
.clickImitate
	visibility: hidden
.messangers
	padding: 0 10px

.content-box-thehome-second
	width: 100%
	display: flex
	@media screen and (max-width: 1100px)
		flex-direction: column
		justify-content: center
		align-items: center

.content-the-home
	max-width: 439px
	width: 100%
	margin-top: 50px
.the-home-second-title
	font-size: 32px
	font-weight: 700
	margin-bottom: 32px
	@media screen and (max-width: 480px)
		margin-bottom: 20px
	@media screen and (max-width: 375px)
		margin-bottom: 12px
		font-size: 28px

.the-home-second-paragraf
	font-size: 16px
	font-weight: 400
	margin-bottom: 28px
	@media screen and (max-width: 480px)
		margin-bottom: 20px
.the-home-second-recomeid
	font-size: 12px
	font-weight: 400
	margin-top: 64px
	margin-bottom: 60px
	@media screen and (max-width: 480px)
		font-size: 10px
		margin-top: 18px
		margin-bottom: 55px
	@media screen and (max-width: 375px)
		margin-bottom: 10px

.the-home-secong-img-company
	width: 100%
	max-width: 217px
	margin: 0 auto
	height: auto
	@media screen and (max-width: 480px)
		padding-bottom: 20px
	@media screen and (max-width: 375px)
		padding-bottom: 0px

.the-home-second-img
	max-width: 579px
	margin-right: 130px
	width: 100%
	@media screen and (max-width: 1100px)
		margin: 0 auto
		max-width: 350px
	@media screen and (max-width: 480px)
		max-width: 278px
	@media screen and (max-width: 375px)
		max-width: 240px
		margin-bottom: -27px
		margin-top: -35px

.copyright
	margin: 20px
	text-align: center
	color: rgba(0, 0, 0, 0.4)
</style>
